import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContextWeb from '../contexts/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function PrivateRoute({ component: Component, ...props }) {
  const { signed, loading } = useContext(AuthContextWeb);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw'
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <Route {...props} render={() => (signed ? <Component {...props} /> : <Redirect to="/" />)} />
  );
}
