// Components
import React, { useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

// Dialogs
import DeleteUserDialog from "../Dialogue/DeleteUserDialog";
import EditUserDialog from "../Dialogue/EditUserDialog";
import RestoreUserDialog from "../Dialogue/RestoreUserDialog";

// Icons
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReplayIcon from "@material-ui/icons/Replay";

// Custom Styling
import { Grid } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useState, useEffect } from "react";

// Backend
import apiWeb from "../../services/api";
import AuthContextWeb from "../../contexts/auth";

import useStyles from "./styles.js";

const localTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#3B5998", // facebook's colour
    },
    secondary: {
      main: "#bf0000",
    },
    error: {
      // main: "#ff604f" // gambiarra
      main: "#4aa000",
    },
  },
  overrides: {
    MuiGrid: {
      container: {
        marginTop: "1rem",
        marginBottom: "1rem",
      },
    },
  },
});

export default function Orders() {
  const { token, userId } = useContext(AuthContextWeb);
  const classes = useStyles();

  // checkbox status
  const [state, setState] = React.useState({
    displayDelUsersCheck: false,
    displayAdminsCheck: false,
  });

  // Request Status States
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Dialog States
  const [deleteUserState, setDeleteUserState] = useState(false);
  const [editUserState, setEditUserState] = useState(false);
  const [restoreUserState, setRestoreUserState] = useState(false);

  // Action user id
  const [actionUserId, setActionUserId] = useState(-1);
  const [disable, setDisabled] = useState(-1);

  // Searchbar States
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [users, setUsers] = useState([]); // All users from DB
  const [searchQuery, setSearchQuery] = useState(""); // content from searchbar

  // Filter options (EXTRA)
  const [delUsersCheck, setDelUsersCheck] = useState(false);
  const [displayAdmins, setDisplayAdmins] = useState(false);
  const [filterField, setFilterField] = useState("");

  // Dialog Trigger Functions
  const deleteUser = (userId) => {
    setActionUserId(userId);

    if (!deleteUserState) setDeleteUserState(true);
    else setDeleteUserState(false);
  };

  const editUser = (userId) => {

    setActionUserId(userId);

    if (!editUserState) {
      setEditUserState(true);
    } else setEditUserState(false);

  };

  const onUpdate = () => {
    loadUsers();
  };

  const restoreUser = (userId) => {
    setActionUserId(userId);

    if (!restoreUserState) setRestoreUserState(true);
    else setRestoreUserState(false);
  };

  // Toolbar checkbox options functions (extra filters)
  const DelUsersOnChange = (event) => {
    setDelUsersCheck(event.target.checked);
  };

  const DisplayAdminOnChange = (event) => {
    setDisplayAdmins(event.target.checked);
  };

  const searchFieldOnChange = (event) => {
    setFilterField(event.target.value);
    console.log(event.target.value);
  };

  // search bar
  useEffect(
    () => {
      setError(false);
      setSuccess(true);

      if (searchQuery.trim().length) {
        const result = filteredUsers.filter((str) => {
          // TODO: checklist with all fields
          var field = str.nome;

          if (filterField == 10) field = str.nome;
          else if (filterField == 20) field = str.email;
          else if (filterField == 30) field = str.empresa;

          return field.toLowerCase().includes(searchQuery.toLowerCase());
        });

        if (result === "") {
          setError(true);
          setSuccess(false);
        } else {
          setFilteredUsers(result);
        }
      } else {
        setFilteredUsers(users);
      }
    },
    [searchQuery],
    [filterField]
  );

  const loadUsers = async () => {
    try {
      apiWeb
        .get("usuarios/", { headers: { "x-access-token": token } })
        .then((res) => {
          setUsers(res.data);
          setFilteredUsers( res.data.reverse().filter((item, index) => { return item.id != userId }) );
        })
        .catch((error) => {
          console.log(error);
        });

      // setting statuses
      setSuccess(true);
      setError(false);
    } catch (err) {
      setSuccess(false);
      setError(true);
    }
  };

  //loading all users as rows in the table
  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <React.Fragment>
      {/* Displaying the Dialogs on screen */}
      <DeleteUserDialog onUpdate={onUpdate} id={actionUserId} open={deleteUserState} />
      <EditUserDialog
        id={actionUserId}
        adminMode={false}
        open={editUserState}
        onUpdate={onUpdate}
      />
      <RestoreUserDialog onUpdate={onUpdate} id={actionUserId} open={restoreUserState} />

      <div className={classes.container}>
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Usuários
        </Typography>

        <Divider />

        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.dashboardOption}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedA}
                onChange={DelUsersOnChange}
                name="displayDelUsersCheck"
              />
            }
            label="Exibir usuários deletados"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedB}
                onChange={DisplayAdminOnChange}
                name="displayAdminsCheck"
              />
            }
            label="Exibir ADMINs"
          /> */}
          <TextField
            className={classes.customInput}
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <>
              //     <Select
              //       variant="filled"
              //       labelId="filterField"
              //       id="filterField"
              //       value={filterField}
              //       onChange={searchFieldOnChange}
              //     >
              //       <MenuItem value={10}>Nome</MenuItem>
              //       <MenuItem value={20}>Empresa</MenuItem>
              //       <MenuItem value={30}>Email</MenuItem>
              //     </Select>
              //   </>
              // ),
            }}
            placeholder="Pesquisar por nome..."
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
          />{" "}
        </Grid>

        <Divider />

        <MuiThemeProvider theme={localTheme}>
          <Table stickyHeader size="small" className={classes.tableResponsive}>
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnName} align="left">
                  Nome
                </TableCell>
                <TableCell className={classes.columnName} align="left">
                  Email
                </TableCell>
                <TableCell className={classes.columnName} align="left">
                  Empresa
                </TableCell>
                <TableCell className={classes.columnName} align="left">
                  Admin
                  <Tooltip title="Para promover outros usuários a administradores, clique no Lápis ✏️ ">
                    <HelpOutlineIcon style={{ fontSize: "15px" }} />
                  </Tooltip>
                </TableCell>
                <TableCell align="right" className={classes.columnName}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!filteredUsers.length || error ? (
                <div className={classes.errorMessage}>
                  {" "}
                  <p>Erro ao pegar user(s) do banco!!</p>
                </div>
              ) : null}
              {success &&
                filteredUsers.map((user, index) => (
                  <TableRow key={user.id} hover>
                    <TableCell>{user.nome}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.empresa}</TableCell>
                    <TableCell>
                      {user.isAdmin ? (
                        <Tooltip title="É Admin">
                          <CheckIcon color="error" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Não é Admin">
                          <ClearIcon color="secondary" />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {!user.isDeleted ? (
                        <>
                          <Tooltip title="Editar">
                            <IconButton
                              aria-label="edit"
                              onClick={() => editUser(user.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Deletar">
                            <IconButton
                              onClick={() => deleteUser(user.id)}
                              aria-label="delete"
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Restaurar">
                          <IconButton
                            aria-label="restore"
                            onClick={() => restoreUser(user.id)}
                          >
                            <ReplayIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  );
}
