import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles.js";

// icons
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DoneIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/Warning";
import BlockIcon from "@material-ui/icons/Block";

export default function AlertDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(props.open);
  const [msg, setMessage] = useState(props.msg);
  const [title, setTitle] = useState(props.title);
  const [icon, setIcon] = useState(props.icon); // error, confirmation, warning, prohibited

  const handleClose = () => {
    setOpen(false);
    if(props.onClose) props.onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              {(() => {
                if (icon === "error")
                  return <ErrorOutlineIcon fontSize="large" />;
                else if (icon === "confirmation")
                  return <DoneIcon fontSize="large" />;
                else if (icon === "warning")
                  return <WarningIcon fontSize="large" />;
                else if (icon === "prohibited")
                  return <BlockIcon fontSize="large" />;
              })()}
            </div>
            <h4>{title}</h4>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
