import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles.js";

import InfoIcon from "@material-ui/icons/Info";

import { Redirect } from "react-router-dom";

export default function AboutDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  // gambiarra? Maybe
  useEffect(() => {
    if (props.open) setOpen(props.open);
  }, [props.open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              <InfoIcon fontSize="large" />
            </div>
            {"A MaxAuto"}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              {/* Criado de modo a complementar a utilização do aplicativo mobile
              homônimo, esta plataforma cumpre funções básicas de um painel de
              adminstrador. Assim, é possível visualizar e adminstrar os
              usuários cadastrados, após efetuado um login por uma conta de
              status "ADMIN". */}
              Fundada no ano de 1999, a Maxauto iniciou sua história como importadora de peças automotivas. Em busca constante por oferecer o melhor aos seus clientes, em 2003 expandiu seu ramo de atuação também para a fabricação de peças. Desde então, a empresa investe em tecnologia para continuar garantindo a satisfação e superando as expectativas de seus clientes.
              Atendendo às normas fiscais e ambientais do Brasil, a Maxauto é especialista na fabricação de sensores de injeção eletrônica para veiculos de passeio. A industria foi pioneira na fabricação de sensores de pressão (MAP) no país.
              Com sua sede em Campo Grande, no Mato Grosso do Sul, a Maxauto tem suas peças comercializadas no Brasil e tambem atende o mercado internacional. Exportamos para outros paises da América do Sul e da Europa, o que demonstra a confiança e o compromisso da Maxauto em fabricar peças automotivas de qualidade.
            </div>

            <div className={classes.aboutText}>
              <p>Todos os direitos reservados a MaxAuto Electronics.</p>
              <p>Aplicativo desenvolvido por ICMC Júnior, 2020/21.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" color="error">
            OK!
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
