import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  aboutText: {
    marginTop: "2rem",
    borderTop: "1px solid var(--neutro-medio)",
    fontSize: "var(--text-smallest)"
  },
  titleContainer: {
    display: "flex",
  },
  title: {
    display: "flex",
    width: "45px",
    // backgroundColor:"red",
  },
}));

export default useStyles;
