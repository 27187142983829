import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "var(--prim-vermelho)",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  dashboardOptions: {
    marginBottom: theme.spacing(5),
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  customInput: {
    marginLeft: "10%",
  },
  mainLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  errorMessage: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: "var(--erro-vermelho)",
    fontSize: "var(--text-medium)",
  },
  container: {
    // boxShadow: "0 8px 6px -6px black",
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  columnName: {
    fontWeight: "bold",
  },
  tableResponsive: {
    width: "100%",
    overflowX: "auto",
    
    // activate these properties to activate column responsivity
    // if you wish to only use x-overflow, comment these 3 lines
    tableLayout: 'fixed',
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  },
}));

export default useStyles;
