import { React, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Tooltip from "@material-ui/core/Tooltip";

// Dialogs
import EditUserDialog from "../Dialogue/EditUserDialog";
import AboutDialog from "../Dialogue/AboutDialog";

export default function MainListItems() {
  // Dialog Statuses
  const [aboutState, setAboutState] = useState(false);
  const [editUserState, setEditUserState] = useState(false);

  // const [userId, setUserId] = useState(localStorage.getItem('user:id'))
  const userId = localStorage.getItem("user:id");

  // OnClick Events
  const openProfile = () => {
    if (!editUserState) setEditUserState(true);
    else setEditUserState(false);
  };

  const openDashboard = () => {
    // alert("should open dashboard");

    // closes other possible dialogs on screen
    setEditUserState(false);
    setAboutState(false);
  };

  const openAbout = () => {
    if (!aboutState) setAboutState(true);
    else setAboutState(false);
  };

  return (
    <>
      {" "}
      {/* Dialogs */}
      <AboutDialog open={aboutState} />
      <EditUserDialog id={userId} adminMode={true} onUpdate={() => {}} open={editUserState} />
      {/* Drawer Items */}
      <Tooltip title="Perfil" placement="right">
        <ListItem button onClick={openProfile}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Perfil" />
        </ListItem>
      </Tooltip>
      <Tooltip title="Painel" placement="right">
        <ListItem button onClick={openDashboard}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Painel" />
        </ListItem>
      </Tooltip>
      <Tooltip title="Sobre" placement="right">
        <ListItem button onClick={openAbout}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Sobre" />
        </ListItem>
      </Tooltip>
    </>
  );
}
