import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

export default function RoutesWeb() {
  return (
    <Switch>
      <Route path="/" component={Login} exact />
      <PrivateRoute path="/dashboard" component={Dashboard} />
    </Switch>
  );
}
