import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    outlineColor: "var(--erro-vermelho)",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
  },
  mainLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(16),
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px solid var(--prim-vermelho)",
    borderRadius: "20px",
    padding: "2rem",
    // boxShadow: "  0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12)"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--erro-vermelho)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    fontWeight: "bold",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "var(--prim-vermelho)",
    "&:hover": {
      background: "var(--erro-vermelho)",
    },
  },
}));

export default useStyles;
