import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  aboutText: {
    marginTop: "2rem",
    borderTop: "1px solid var(--neutro-medio)",
    fontSize: "var(--text-smallest)",
  },
  titleContainer: {
    display: "flex",
    paddingBottom: "0.5rem",
    borderBottom: "2px solid var(--neutro-medio)",
  },
  title: {
    display: "flex",
    width: "45px",
    // backgroundColor:"red",
  },
}));

export default useStyles;
