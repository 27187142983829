import React, { Component, useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles.js';

import { Redirect } from 'react-router-dom';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthContextWeb from '../../../contexts/auth.js';

export default function ConfirmExitDialog(props) {
  const { signOut } = useContext(AuthContextWeb);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleClose = () => {
    setOpen(false);
    signOut();
    // setRedirect(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // gambiarra? Maybe
  useEffect(() => {
    if (props.open) setOpen(props.open);
  }, [props.open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              <ExitToAppIcon fontSize="medium" />
            </div>
            {'Deseja Sair?'}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Verifique se fez tudo o que deveria fazer aqui...'}
          </DialogContentText>
        </DialogContent>

        <Grid container direction="row" justify="flex-end" alignItems="center">
          {' '}
          <DialogActions>
            <Button onClick={handleCancel} color=" primary">
              Cancelar
            </Button>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Sair
            </Button>
          </DialogActions>
        </Grid>
        {redirect && <Redirect to="/" />}
      </Dialog>
    </React.Fragment>
  );
}
