import React, { Component, useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Switch from "@material-ui/core/Switch";

import Divider from "@material-ui/core/Divider";
import InputMask from "react-input-mask";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles.js";
import "./styles.css";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";

import AlertDialog from "../AlertDialog/index";

// Backend
import apiWeb from "../../../services/api";
import AuthContextWeb from "../../../contexts/auth.js";

export default function EditUserDialog(props) {
  const { token } = useContext(AuthContextWeb);
  // Brazilian state codes
  const brazilianStates = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const classes = useStyles();

  // Meta Statuses
  const [open, setOpen] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [error, setError] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  // User props State
  const [userId, setUserId] = useState(-1);

  // User data
  const [name, setName] = useState("");
  const [cpf, setCPF] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [cnpj, setCNPJ] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  // Location fields
  const [city, setCity] = useState("");
  const [estado, setEstado] = useState( brazilianStates[0] );

  function cpfMask(value){
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const parseCPF = (cpf) => {

    while (cpf.includes('.')) cpf = cpf.replace('.', '');
    while (cpf.includes('-')) cpf = cpf.replace('-', '');

    return cpf;

  }

  const parseCNPJ = (cnpj) => {

    while (cnpj.includes('.')) cnpj = cnpj.replace('.', '');
    while (cnpj.includes('-')) cnpj = cnpj.replace('-', '');
    while (cnpj.includes('/')) cnpj = cnpj.replace('/', '');

    return cnpj;

  }

  const handleIsAdmin = (event) => {
    setIsAdmin(event.target.checked);

    console.log("Status: ", event.target.checked);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const getUser = () => {
    apiWeb
      .get(`usuarios/findById/${userId}`, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        setName(res.data.nome);
        setCPF(res.data.cpf);
        setEmail(res.data.email);
        setCompany(res.data.empresa);
        setCNPJ(res.data.cnpj);
        setIsAdmin(res.data.isAdmin);
        setEstado(res.data.estado);
        setCity(res.data.cidade);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateData = () => {

    if( !name || !email || !cnpj || !cpf || !company || !city ) {

      return false;

    }

    return true;

  }

  async function updateUser() {

    if (!validateData()) {
    
      // alert("Preencha todos os campos obrigatorios!");
      setFieldsError(true);
      return;
    
    }

    const data = {

      cpf: parseCPF(cpf),
      cnpj: parseCNPJ(cnpj),
      nome: name,
      empresa: company,
      email: email,
      isAdmin: isAdmin,
      estado: estado,
      cidade: city,

    }

    console.log(data);

    await apiWeb
      .post(
        `usuarios/update/${userId}`, data,
        {
          headers: { "x-access-token": token },
        }
      )
      .then(async (res) => {
        // console.log(res);
        if (res.status === 200) {
          // Closing Routine
          setOpen(false);
          setConfirmation(true);
          await timeout(1500); //for 1 sec delay
          setConfirmation(false);
          props.onUpdate();

        }
      })
      .catch(async (err) => {
        console.error(err);
        // if (err.response.status === 400) {
          // Closing Routine
          setOpen(false);
          await timeout(500); //for 1 sec delay
          setError(true);
          await timeout(1500); //for 1 sec delay
          setConfirmation(false);
        // }
      });
  }

  // Dialog Status
  const handleCancel = () => {
    setOpen(false);
  };

  // Setting Open/Close states
  useEffect(
    () => {

      setOpen(props.open);
      if (props.adminMode) setAdminMode(props.adminMode);
      if (props.id) setUserId(props.id);

    },
    [props.open],
    [props.adminMode],
    [props.id]
  );

  useEffect(() => {

    // loading user's info
    if (userId !== -1) getUser();

  }, [userId]);

  return (
    <React.Fragment>

      {fieldsError && (
        <AlertDialog
          onClose={ () => { setFieldsError(false) } }
          open={true}
          msg={`Preencha todos os campos corretamente!`}
          title={"Erro!"}
          icon={"error"}
        />
      )}

      {error && (
        <AlertDialog
          onClose={ () => { setError(false) } }
          open={true}
          msg={` Erro ao alterar '${name}' !`}
          title={"Erro!"}
          icon={"error"}
        />
      )}

      {confirmation && (
        <AlertDialog
          onClose={ () => { setConfirmation(false) } }
          open={true}
          msg={`Dados de '${name}' alterados com sucesso !`}
          title={"Sucesso!"}
          icon={"confirmation"}
        />
      )}

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          {adminMode ? (
            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                <AccountCircleIcon fontSize="large" />
              </div>
              {`Perfil do ADM ${name}`}
            </div>
          ) : (
            <div className={classes.iconContainer}>
              <div className={classes.icon}>
                <EditIcon fontSize="large" />
              </div>
              {`Deseja Alterar as Informações de ${name}?`}
            </div>
          )}
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Informações Básicas
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Nome"
                fullWidth
                autoComplete="given-name"
                value={name}
                onChange={(text) => {
                  setName(text.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
                disabled
                value={email}
                onChange={(text) => {
                  setEmail(text.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                id="cpf"
                name="cpf"
                label="CPF"
                value={ cpfMask(cpf) }
                onChange={(text) => {
                  setCPF( cpfMask(text.target.value) );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputMask
                value={cnpj}
                onChange={(text) => {
                  setCNPJ(text.target.value);
                }}
                mask="99.999.999/9999-99"
                shrink="false"
              >
                {() => (
                  <TextField
                    required
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    fullWidth
                    shrink="false"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="empresa"
                name="empresa"
                label="Empresa"
                fullWidth
                value={company}
                shrink="false"
                onChange={(text) => {
                  setCompany(text.target.value);
                }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
              sm={6}
            >
              <Grid item>
                <InputLabel shrink="false" id="estado-select">
                  Estado
                </InputLabel>
                <Select
                  labelId="estado"
                  id="estado-select"
                  value={estado}
                  onChange={(e) => { setEstado(e.target.value) }}
                >
                  {brazilianStates.map((state, index) => (
                    <MenuItem value={state} key={index}>{state}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="cidade"
                  name="cidade"
                  label="cidade"
                  fullWidth
                  value={city}
                  shrink="false"
                  onChange={(text) => {
                    setCity(text.target.value);
                  }}
                />
              </Grid>
            </Grid>

            {!adminMode ? (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Status da Conta
                </Typography>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  xs={12}
                  spacing={1}
                >
                  <Grid item>Usuário</Grid>
                  <Grid item>
                    <div className="switch">
                      <input
                        id="isExecutingProj"
                        type="checkbox"
                        className="switch-input"
                        onChange={(event) => {
                          handleIsAdmin(event);
                        }}
                        checked={isAdmin}
                      />
                      <label htmlFor="isExecutingProj" className="switch-label">
                        Switch
                      </label>
                    </div>
                  </Grid>
                  <Grid item>Admin</Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>

        <Grid container direction="row" justify="flex-end" alignItems="center">
          {" "}
          <DialogActions>
            <Button onClick={handleCancel}>Cancelar</Button>

            <Button variant="contained" color="secondary" onClick={updateUser}>
              Salvar
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
