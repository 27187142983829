import React from 'react';
// import Routes from './routes';
import RoutesWeb from '../src/routes/index';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AuthProviderWeb } from '../src/contexts/auth';

import './styles/global.css';
import { BrowserRouter } from 'react-router-dom';

// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#252733',
    },
    secondary: {
      main: '#bf0000',
    },
    success: {
      main: '#4aa000',
    },
    error: {
      // main: "#ff604f" // gambiarra
      main: '#4aa000',
    },
    common: {
      main: '#42BECF',
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <AuthProviderWeb>
            {/* <Routes /> */}
            <RoutesWeb />
          </AuthProviderWeb>
        </div>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
