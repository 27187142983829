import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import { Redirect } from "react-router-dom";

// import useStyles from "./styles.js";

export default function Login() {
  return (
    <div id="login-page">
      <Dashboard />
    </div>
  );
}
