import React, { useState, useContext } from "react";
import AuthContextWeb from "../../contexts/auth";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-red2x.png";

import useStyles from "./styles";
import AlertDialog from "../../components/Dialogue/AlertDialog";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.maxauto.com.br/">
        MaxAuto
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const { signInWeb, signed, error } = useContext(AuthContextWeb);

  const [email, setEmail] = useState("");
  const [password, setPassoword] = useState("");

  // const [redirect, setRedirect] = useState(false);

  function onSubmit(event) {
    event.preventDefault();

    signInWeb(email, password);
  }

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {error && (
        <AlertDialog
          open={true}
          msg={
            " Email ou senha incorreta. Verifique a escrita. Se persistir, contate o suporte."
          }
          title={"Erro ao logar"}
          icon={"error"}
        />
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.mainLogo}>
          <img src={logo} alt="logo MaxAuto" />
        </div>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Login{" "}
          </Typography>

          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Endereço de email"
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              className={classes.textField}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              className={classes.textField}
              onChange={(e) => {
                setPassoword(e.target.value);
              }}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
              {signed && <Redirect to="/dashboard" />}
            </Button>

            {/* <Grid
              container
              direction="column"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs>
                <Link href="#" variant="body2">
                  {"Esqueceu a senha?"}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Não tem uma conta? Cadastre-se"}
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
