import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    paddingBottom: "0.5rem",
    borderBottom: "2px solid var(--neutro-medio)",
    // backgroundColor: "var( --erro-vermelho)",
    // color: "var( --secun-branco)",
  },
  title: {
    display: "flex",
    width: "45px",
  },
}));

export default useStyles;
