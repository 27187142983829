import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import apiWeb from '../services/api';

const AuthContextWeb = createContext({ signed: false, userId: '' });

export const AuthProviderWeb = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadStorageData() {
      const storageUser = await localStorage.getItem('user:id');
      const storageToken = await localStorage.getItem('user:token');

      if (storageUser && storageToken) {
        setUserId(JSON.parse(storageUser));
        setToken(JSON.parse(storageToken));
      }
      setLoading(false);
    }
    loadStorageData();
  }, []);

  function getUser(userId, userToken) {
    apiWeb
      .get(`usuarios/findById/${userId}`, { headers: { 'x-access-token': userToken } })
      .then((res) => {
        setUsername(res.data.nome);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function signInWeb(email, password) {
    setError(false);
    apiWeb
      .post('/usuarios/login', {
        email: email,
        senha: password,
      })
      .then((serverRespose) => {
        setLoading(true);
        setTimeout(() => setLoading(false), 1000);
        if (serverRespose.data.isAdmin == 1) {
          getUser(serverRespose.data.id, serverRespose.data.accessToken);
          setUserId(serverRespose.data.id);
          setToken(serverRespose.data.accessToken);

          axios.defaults.headers.common['x-access-token'] = token;

          localStorage.setItem('user:id', JSON.stringify(serverRespose.data.id));
          localStorage.setItem('user:token', JSON.stringify(serverRespose.data.accessToken));
        }
        throw new Error();
      })
      .catch(() => {
        setError(true);
      });
  }

  async function signOut() {
    await localStorage.clear();
    setUserId(null);
    setUsername(null);
    setToken(null);
    setError(false);
    axios.defaults.headers.common['x-access-token'] = null;
  }

  return (
    <AuthContextWeb.Provider
      value={{
        signed: Boolean(userId),
        userId,
        token,
        loading,
        error,
        signInWeb,
        signOut,
      }}
    >
      {children}
    </AuthContextWeb.Provider>
  );
};

export default AuthContextWeb;
