import React, { Component, useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles.js';

import AlertDialog from '../AlertDialog/index';

import ReplayIcon from '@material-ui/icons/Replay';

// Backend
import apiWeb from '../../../services/api';
import AuthContextWeb from '../../../contexts/auth.js';

export default function RestoreUserDialog(props) {
  const { token } = useContext(AuthContextWeb);
  const classes = useStyles();

  // Meta States
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  // User Content
  const [userId, setUserId] = useState(-1);

  // Dialog Status
  const handleCancel = () => {
    setOpen(false);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  // backend function
  function enableUser() {
    apiWeb
      .post(
        `usuarios/disable/${userId}`,
        {
          isDeleted: false,
        },
        { headers: { 'x-access-token': token } }
      )
      .then(async (res) => {
        if (res.status === 200) {
          // Closing Routine
          setOpen(false);
          setConfirmation(true);
          await timeout(1500); //for 1 sec delay
          setConfirmation(false);

          props.onUpdate();
        }
      })
      .catch(async (err) => {
        // console.error(err.response);
        if (err.response.status === 400) {
          // Closing Routine
          setOpen(false);
          await timeout(500); //for 1 sec delay
          setError(true);
          await timeout(1500); //for 1 sec delay
          setConfirmation(false);
        }
      });
  }

  // gambiarra? Maybe
  useEffect(
    () => {
      if (props.open) setOpen(props.open);
      if (props.id) setUserId(props.id);
    },
    [props.open],
    [props.id]
  );

  return (
    <React.Fragment>
      {error && (
        <AlertDialog
          open={true}
          msg={` Erro ao recuperar usuário !`}
          title={'Erro!'}
          icon={'error'}
        />
      )}

      {confirmation && (
        <AlertDialog
          open={true}
          msg={`Usuário restaurado com sucesso !`}
          title={'Sucesso!'}
          icon={'confirmation'}
        />
      )}

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <ReplayIcon fontSize="large" />
            </div>
            {'Deseja Restaurar Usuário?'}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta ação pode ser posteriormente revertida ao deletar o usuário.
          </DialogContentText>
        </DialogContent>

        <Grid container direction="row" justify="flex-end" alignItems="center">
          {' '}
          <DialogActions>
            <Button onClick={handleCancel}>Cancelar</Button>

            <Button variant="contained" onClick={enableUser} color="secondary">
              Sim!
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
